import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideRouter, RouteReuseStrategy } from '@angular/router';
import {
  IonicRouteStrategy,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createErrorHandler } from '@sentry/angular';
import { AnalyticsService } from '@service/analytics.service';
import { AppInitializeService } from '@service/app-initialize.service';
import { ErrorService } from '@service/error.service';
import { NetworkService } from '@service/network.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { catchError, Observable, of } from 'rxjs';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { TokenInterceptor } from './app/interceptor/token.interceptor';
import { KeycloakService } from './app/keycloak/service/keycloak.service';
import { environment } from './environments/environment';

const initializeKeycloakService = (
  networkService: NetworkService,
  keycloakService: KeycloakService,
) => {
  return async (): Promise<void> => {
    await networkService.init().then(() => keycloakService.init());
  };
};
const analyticsServiceInitializerFactory = (
  analyticsService: AnalyticsService,
) => {
  return (): Observable<void> => analyticsService.initialize();
};
const errorServiceInitializerFactory = (errorService: ErrorService) => {
  return (): Observable<void> => errorService.initialize();
};
const appInitializer = (appInitializeService: AppInitializeService) => {
  return (): Observable<boolean> =>
    appInitializeService.initialize().pipe(catchError(() => of(false)));
};

const httpLoaderFactory = (
  httpBackend: HttpBackend,
): MultiTranslateHttpLoader =>
  new MultiTranslateHttpLoader(httpBackend, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/i18n/countries/', suffix: '.json' },
  ]);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpBackend],
        },
      }),
      GoogleTagManagerModule.forRoot({
        id: environment?.analytics?.google.gtm ?? null,
      }),
    ),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    provideAppInitializer(() => {
      const initializerFn = initializeKeycloakService(
        inject(NetworkService),
        inject(KeycloakService),
      );

      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = errorServiceInitializerFactory(
        inject(ErrorService),
      );

      return initializerFn();
    }),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({ showDialog: false }),
    },
    provideAppInitializer(() => {
      const initializerFn = appInitializer(inject(AppInitializeService));

      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = analyticsServiceInitializerFactory(
        inject(AnalyticsService),
      );

      return initializerFn();
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    provideIonicAngular(),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch(err => console.error(err));
